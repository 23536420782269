/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import Link from 'next/link';

import { M404 } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';

import Content from 'Storybook/local/Content/Content';

import MetaTags from '../MetaTags/MetaTags';
import PageComposer from '../PageComposer/PageComposer';
import texts from '../texts.json';

import styles from './NotFound404.module.scss';

T.setTexts(texts);

const NotFound404 = ({ pageSlug }) => (
	<>
		<MetaTags noIndex title={T.translate('metaTags.pages.404.title')} />
		<PageComposer pageSlug={pageSlug}>
			<Content className={styles.wrapper} element="section">
				<M404 className={styles.M404} />
				<h2 className={styles.heading}>{T.translate('404.title')}</h2>
				<p className={styles.text}>{T.translate('404.detail')}</p>
				<div className={styles.button}>
					<Link legacyBehavior passHref as="/" href="/index">
						<Button
							fullWidth
							as="a"
							element="a"
							icon="chevron"
							label={String(T.translate('404.buttonLabel'))}
							rightIcon="chevron-right"
						/>
					</Link>
				</div>
			</Content>
		</PageComposer>
	</>
);

NotFound404.propTypes = {
	pageSlug: PropTypes.string,
};

NotFound404.defaultProps = {
	pageSlug: '',
};

export default NotFound404;
